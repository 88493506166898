<template>
	<v-container>
		<v-dialog v-model="dialog" persistent max-width="850">
			<v-toolbar dark>
				<v-btn icon @click="close">
					<v-icon>mdi-close</v-icon>
				</v-btn>
				<v-toolbar-title>{{ $t(i18n + '.' + action) }}</v-toolbar-title>
			</v-toolbar>
			<v-card>
				<v-card-text>
					<v-container>
						<v-form ref="userForm" v-model="formValid" lazy-validation>
							<v-row>
								<v-col cols="6">
									<v-text-field v-model="detailObj.nameZh" :label="$t('common.name_zh')" required
										:rules="[v => !!v || $t('common.required')]" />
								</v-col>
								<v-col cols="6">
									<v-text-field v-model="detailObj.nameEng" :label="$t('common.name_eng')" required
										:rules="[v => !!v || $t('common.required')]" />
								</v-col>
							</v-row>
							<v-row v-if="apiTarget == 'merchantPlan'">
								<v-col cols="12" class="py-0">
									<v-textarea v-model="detailObj.descriptionZh" :label="$t('common.description_zh')"
										rows="2" />
								</v-col>
								<v-col cols="12" class="py-0">
									<v-textarea v-model="detailObj.descriptionEng" :label="$t('common.description_eng')"
										rows="2" />
								</v-col>
							</v-row>
							<v-row v-if="apiTarget == 'paymentMethod'">
								<v-col cols="12" class="py-0">
									<v-textarea v-model="detailObj.detailZh" :label="$t('common.detail_zh')" rows="2" />
								</v-col>
								<v-col cols="12" class="py-0">
									<v-textarea v-model="detailObj.detailEng" :label="$t('common.detail_eng')" rows="2" />
								</v-col>
							</v-row>

							<v-row>
								<v-col cols="12" class="py-0" v-if="needImage">
									<v-card>
										<v-card-title>{{ $t('common.image') }}</v-card-title>
										<v-card-text>
											<v-img class="mx-auto"
												v-if="detailObj.image && detailObj.image.large && detailObj.image.large.path"
												:src="detailObj.image.large.path" max-width="400" />
											<file-pond v-else name="upload" ref="pond" :label-idle="$t('img_label')"
												accepted-file-types="image/jpeg, image/png" :files="uploadImageFile"
												allow-multiple="false" max-file-size="5MB"
												v-on:removefile="detailObj.image = ''" />
										</v-card-text>
										<v-card-actions v-if="detailObj.image && detailObj.image.large.path">
											<v-spacer />
											<v-btn @click="detailObj.image = ''" text>
												<v-icon>
													mdi-pencil
												</v-icon>
											</v-btn>
											<v-spacer />
										</v-card-actions>
									</v-card>
								</v-col>
							</v-row>

							<v-row v-if="action == 'edit'">
								<v-col cols="12">
									<v-text-field :value="$moment(detailObj.createdAt).format('YYYY/MM/DD HH:mm')" disabled
										:label="$t('common.created_at')" />
								</v-col>
							</v-row>

							<v-row>
								<v-col cols="6" v-if="action == 'edit'">
									<v-switch v-model="detailObj.published" :label="showStatus(detailObj.published)" />
								</v-col>
							</v-row>
						</v-form>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer />
					<v-btn color="blue darken-1" @click="save(action)">{{ $t('common.save') }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
import { mapMutations } from 'vuex'
import api from '@/api'
//import draggable from 'vuedraggable'
// Import Vue FilePond
import vueFilePond, { setOptions } from 'vue-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

// Import image preview plugin styles
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

// Create component
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilePondPluginFileValidateSize);

export default {
	mounted() {
		let self = this
		this.setOptions({
			server: {
				process: async function (fieldName, files, metadata, load, error, progress, abort) {
					try {
						let formData = new FormData()
						formData.append('file', files)

						let result = await api.post('/image', formData, {
							headers: { 'Content-Type': 'multipart/form-data' }
						})

						if (result) {
							self.detailObj.image = result.data._id
						}
						load(true)
					} catch (err) {
						console.log(err.message);
						abort(true)
					}
				}
			},
			imageResizeMode: 'contain',
		})
	},
	computed: {},
	components: {
		FilePond
	},
	methods: {
		...mapMutations(['PUSH_GLOBAL_SNACK_MESSAGES']),
		async open(action, item, type) {
			this.action = action
			let id = ''
			if (item) id = item._id ? item._id : item.id

			switch (type) {
				case 'cat':
					this.apiTarget = 'category'
					this.i18n = 'cat'
					this.detailObj['image'] = ''
					this.needImage = true
					break
				// case 'teacher':
				// 	this.apiTarget = 'teacher'
				// 	this.i18n = 'teacher'
				// 	this.detailObj['image'] = ''
				// 	this.detailObj['descriptionZh'] = ''
				// 	this.detailObj['descriptionEng'] = ''
				// 	this.needImage = true
				// 	break
				case 'payment_method':
					this.apiTarget = 'paymentMethod'
					this.i18n = 'payment_method'
					this.detailObj['image'] = ''
					this.detailObj['detailEng'] = ''
					this.detailObj['detailZh'] = ''
					this.needImage = true
					break
				case 'merchant_plan':
					this.apiTarget = 'merchantPlan'
					this.i18n = 'merchant_plan'
					this.detailObj['descriptionZh'] = ''
					this.detailObj['descriptionEng'] = ''
					break
				case 'banner':
					this.apiTarget = 'banners'
					this.i18n = 'banners'
					this.detailObj['type'] = ''
					this.detailObj['link'] = ''
					this.detailObj['location'] = ''
					this.needImage = true
					break
			}

			if (this.action == 'edit' && this.apiTarget) {
				try {
					let result = await api.get('/' + this.apiTarget + '/' + id)
					this.detailObj = result.data
				} catch (error) {
					this.PUSH_GLOBAL_SNACK_MESSAGES({
						message: error.response.data.message,
						icon: 'mdi-close',
					})
				}
			}
			this.dialog = true
		},
		async save() {
			let validated = this.$refs.userForm.validate()

			if ((this.needImage) && this.detailObj.image == '') {
				this.PUSH_GLOBAL_SNACK_MESSAGES({
					message: this.$t('global_alert.warning_upload_img'),
					icon: 'mdi-close',
				})

				return false
			}

			if (validated && this.apiTarget) {
				let result = false
				try {
					if (this.action == 'edit') {
						result = await api.patch('/' + this.apiTarget, this.detailObj)
					} else {
						result = await api.post('/' + this.apiTarget, this.detailObj)
					}

					if (result) {
						let targetI18n = (this.action === 'edit') ? 'global_alert.success_edit' : 'global_alert.success_create'
						this.resetForm()
						this.dialog = false
						this.PUSH_GLOBAL_SNACK_MESSAGES({
							message: this.$t(targetI18n),
							icon: 'mdi-check',
						})
					}
				} catch (error) {
					this.PUSH_GLOBAL_SNACK_MESSAGES({
						message: error.response.data.message,
						icon: 'mdi-close',
					})
				}
			}
		},
		showStatus(status) {
			return status ? this.$t('common.enabled') : this.$t('common.disabled')
		},
		close() {
			this.resetForm()
			this.dialog = false
		},
		resetForm() {
			this.$emit('refresh')
			this.confirmPW = ''
			this.detailObj = {
				nameZh: '',
				nameEng: '',
				published: true
			}
			this.uploadImageFile = []
			this.action = ''
			this.apiTarget = ''
			this.i18n = ''
			this.needImage = false
		},
	},
	data: () => ({
		setOptions,
		dialog: false,
		detailObj: {
			nameZh: '',
			nameEng: '',
			published: true
		},
		i18n: '',
		apiTarget: '',
		uploadImageFile: [],
		action: 'edit',
		confirmPW: '',
		formValid: true,
		needImage: false,
	}),
}
</script>